import {LaboratoryService} from "../laboratory/laboratory.service";
import {AuthService} from "../typescript/auth/auth.service";
import {DateUtil, DateUtil as DateUtil2} from "../typescript/util/2.0.0/date-util";
import {NumberUtil} from "../typescript/util/number-util";
import {AppService} from "../typescript/app/app.service";
import {PageResponse} from "../typescript/util/page-response";
import {Sale} from "./sale";
import {CloseSaleAVistaParams} from "./close-sale-a-vista-params";
import {CloseSaleAPrazoParams} from "./close-sale-a-prazo-params";
import {PersonService} from "../person/person.service";
import {SaleItem} from "./sale-item";
import {Category} from "../category/category";
import {SaleListHeaderFilterClosedEnum} from "../typescript/sale/list/header/enum/sale-list-header-filter-closed-enum";
import {
    SaleListHeaderFilterReceivedEnum
} from "../typescript/sale/list/header/enum/sale-list-header-filter-received-enum";
import {SaleListResponse} from "./sale-list-response";
import {CloseOptionsResponse} from "./close-options-response";
import {EntradaAPrazo} from "./entrada-a-prazo";
import {Entrada} from "../typescript/sale/close/aprazo/entrada";
import {CloseSaleCartaoParams} from "./close-sale-cartao-params";
import {AxiosResponse} from "axios";

class SaleService {

    static API_URL = "/api/oss/v4";
    static PAGAMENTO_CARTAO = {
        id: 3,
        descricao: "CARTÃO",
        carteira: {
            id: 4,
            descricao: "Caixa"
        }
    }

    static CPCB_BASIC_ID = 6656;

    static findAll = (startDate: string | undefined, endDate: string | undefined, fechada: boolean | undefined,
                      recebida: boolean | undefined, id: number | undefined, cliente: string | undefined): Promise<SaleListResponse> => {
        return AppService.getAuthorized(this.API_URL, {
            emissaoInicial: startDate,
            emissaoFinal: endDate,
            fechada: fechada,
            recebida: recebida,
            id: id,
            cliente: cliente
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return this.createSaleResponse();
        })
    }

    static saleFilterClosedEnumToBoolean = (saleClosed: SaleListHeaderFilterClosedEnum) => {
        switch (saleClosed) {
            case SaleListHeaderFilterClosedEnum.CLOSED:
                return true;
            case SaleListHeaderFilterClosedEnum.OPENED:
                return false;
            default:
                return undefined;
        }
    }

    static saleFilterReceivedEnumToBoolean = (saleReceived: SaleListHeaderFilterReceivedEnum) => {
        switch (saleReceived) {
            case SaleListHeaderFilterReceivedEnum.RECEIVED:
                return true;
            case SaleListHeaderFilterReceivedEnum.NOT_RECEIVED:
                return false;
            default:
                return undefined;
        }
    }

    static findAllToSearch = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<Sale>> => {
        return AppService.getAuthorized(this.API_URL + "/toSearch", {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            pessoa: sort?.startsWith('pessoa') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static createSalesClosedResponse = () => {
        return {
            sales: []
        }
    }

    static createDocumentsBySaleResponse = () => {
        return {
            documents: []
        }
    }

    static findAllClosed = (startDate: string, endDate: string, id: number, personName: string) => {
        return AppService.getAuthorized(this.API_URL + '/closed', {
            startDate: startDate,
            endDate: endDate,
            id: id,
            personName: personName
        }).then((result) => {
            return result.data;
        }).catch((error) => {
            if (error.response.status === 404) {
                return this.createSalesClosedResponse();
            }
            return null;
        })
    }

    static findAllDocumentsBySale = (saleId: number, payable: boolean) => {
        return AppService.getAuthorized(this.API_URL + "/" + saleId + "/document", {
            payable: payable
        }).then((result) => {
            return result.data;
        }).catch((error) => {
            if (error.response.status === 404) {
                return this.createDocumentsBySaleResponse();
            }
            return null;
        })
    }

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: Sale, insertCpcbBasic?: boolean) {
        let itemToSave = item;
        if (itemToSave.laboratorio && !itemToSave.laboratorio.id) {
            itemToSave.laboratorio = null;
        }
        if (itemToSave.id) {
            return AppService.postAuthorized(this.API_URL + "/" + itemToSave.id, itemToSave, {
                insertCpcbBasic: insertCpcbBasic
            });
        } else {
            return AppService.postAuthorized(this.API_URL, itemToSave, {
                insertCpcbBasic: insertCpcbBasic
            });
        }
    }

    static getCloseOptions = async (removeCard: boolean): Promise<CloseOptionsResponse> => {
        return AppService.getAuthorized(this.API_URL + '/closeOptions', {removeCard}).then((result: AxiosResponse<CloseOptionsResponse>) => {
            return result.data;
        });
    }

    static getValorDeCustoComLucro = async (id: number): Promise<number> => {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/custoComLucro').then((result: AxiosResponse<number>) => {
            return result.data;
        }).catch(() => {
            return 0;
        })
    }

    static updateStatus2(id: number, status2?: string) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/status2", null, {status2: status2});
    }

    static updateStatus3(id: number, status3: string) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/status3", null, {status3: status3});
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static duplica(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/duplica');
    }

    static delivery(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/delivery');
    }

    static advice(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/advice');
    }

    static cancelDelivery(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/cancelDelivery');
    }

    static cancelAdvice(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/cancelAdvice');
    }

    static recebe(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/recebe');
    }

    static cancelaRecebimento(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id + '/cancelaRecebimento');
    }

    static recibo = (saleId: number) => {
        return AppService.getAuthorized(this.API_URL + '/' + saleId + '/recibo', null, "arraybuffer");
    }

    static laboratoryOrder = (saleId: number) => {
        return AppService.getAuthorized(this.API_URL + '/' + saleId + '/laboratoryOrder', null, "arraybuffer");
    }

    static laboratoryWarranty = (saleId: number) => {
        return AppService.getAuthorized(this.API_URL + '/' + saleId + '/warranty', null, "arraybuffer");
    }

    static confissao = (saleId: number) => {
        return AppService.getAuthorized(this.API_URL + '/' + saleId + '/confissao', null, "arraybuffer");
    }

    static closeSaleCash = (saleId: number, params: CloseSaleAVistaParams) => {
        return AppService.postAuthorized(this.API_URL + '/' + saleId + '/close/cash', params);
    }

    static closeSaleParcel = (saleId: number, params: CloseSaleAPrazoParams) => {
        return AppService.postAuthorized(this.API_URL + '/' + saleId + '/close/parcel', params);
    }

    static closeSalePreviewCash = (saleId: number, params: CloseSaleAVistaParams) => {
        return AppService.postAuthorized(this.API_URL + '/' + saleId + '/close/preview/cash', params);
    }

    static closeSalePreviewParcel = (saleId: number, params: CloseSaleAPrazoParams) => {
        return AppService.postAuthorized(this.API_URL + '/' + saleId + '/close/preview/parcel', params);
    }

    static closeSaleCard = (saleId: number, params: CloseSaleCartaoParams) => {
        return AppService.postAuthorized(this.API_URL + '/' + saleId + '/close/card', params);
    }

    static closeSalePreviewCard = (saleId: number, params: CloseSaleCartaoParams) => {
        return AppService.postAuthorized(this.API_URL + '/' + saleId + '/close/preview/card', params);
    }

    static createSaleResponse = () => {
        const response: SaleListResponse = {
            vendas: [],
            valorTotal: 0,
            valorTotalFechada: 0,
            quantidadeTotalAberta: 0,
            comprou: 0,
            fezOrcamento: 0,
            fezEmOutroLugar: 0,
            naoPrecisou: 0,
            achouCaro: 0
        }
        return response;
    }

    static calculaValorPercentual = (valor: number, percentual: number) => {
        let valorBruto = 0;
        if (valor) {
            valorBruto = Number(valor);
        }
        let percentualDesconto = 0
        if (percentual) {
            percentualDesconto = Number(percentual);
        }
        return NumberUtil.arredonda(valorBruto * percentualDesconto / 100, 2);
    }

    static newEntradaAPrazo(sale: Sale): EntradaAPrazo {
        return {
            valor: '',
            tipoDePagamento: null,
            bandeira: null,
            data: DateUtil2.toJavaSqlDate(DateUtil2.currentDate()),
            valorDesconto: 0,
            percentualDesconto: 0,
            valorFinal: (sale.valorTotal || 0)
        };
    }

    static createSale() {
        const sale: Sale = {
            createdDate: '',
            vendedor: AuthService.getUserDetails(),
            pessoa: PersonService.createPerson(false, false),
            laboratorio: LaboratoryService.createLaboratory(),
            previsaoEntrega: '',
            itens: [],
            doctor: '',
            crm: '',
            operation: 'VENDA'
        };
        return sale;
    }

    static createSaleItemFromServicoParaInserir(servicoParaInserir: SaleItem) {
        const saleItem: SaleItem = {
            servico: servicoParaInserir.servico,
            quantidade: Number(servicoParaInserir.quantidade),
            valorUnitario: Number(servicoParaInserir.valorUnitario),
            valorTotal: (servicoParaInserir.quantidade || 0) * (servicoParaInserir.valorUnitario || 0),
            observacao: servicoParaInserir.observacao,
            valorUnitarioMinimo: servicoParaInserir.valorUnitarioMinimo,
            odEsferico: servicoParaInserir.odEsferico,
            odCilindrico: servicoParaInserir.odCilindrico,
            odEixo: servicoParaInserir.odEixo,
            odAdicao: servicoParaInserir.odAdicao,
            odDnp: servicoParaInserir.odDnp,
            odAltura: servicoParaInserir.odAltura,
            oeEsferico: servicoParaInserir.oeEsferico,
            oeCilindrico: servicoParaInserir.oeCilindrico,
            oeEixo: servicoParaInserir.oeEixo,
            oeAdicao: servicoParaInserir.oeAdicao,
            oeDnp: servicoParaInserir.oeDnp,
            oeAltura: servicoParaInserir.oeAltura,
            pantoscopicAngle: servicoParaInserir.pantoscopicAngle,
            curvatureAngle: servicoParaInserir.curvatureAngle,
            croRightEye: servicoParaInserir.croRightEye,
            croLeftEye: servicoParaInserir.croLeftEye,
            readingDistance: servicoParaInserir.readingDistance,
            dominantEye: servicoParaInserir.dominantEye,
            coefficientHeadEye: servicoParaInserir.coefficientHeadEye,
            depende: servicoParaInserir.depende,
            numeroItem: servicoParaInserir.numeroItem
        };
        return saleItem;
    }

    static createItemParaInserir(saleItem?: SaleItem) {
        return saleItem ? this.createSaleItemFromSaleItem(saleItem) : this.createSaleItem();
    }

    private static createSaleItem() {
        const saleItem: SaleItem = {
            numeroItem: 0,
            servico: null,
            produtoInvalido: true,
            quantidade: 1,
            valorUnitario: 0,
            observacao: '',
            valorUnitarioMinimo: 0,
            odEsferico: '',
            odCilindrico: '',
            odEixo: '',
            odAdicao: '',
            odDnp: '',
            odAltura: '',
            oeEsferico: '',
            oeCilindrico: '',
            oeEixo: '',
            oeAdicao: '',
            oeDnp: '',
            oeAltura: '',
            pantoscopicAngle: '',
            curvatureAngle: '',
            croRightEye: '',
            croLeftEye: '',
            readingDistance: '',
            dominantEye: '',
            coefficientHeadEye: '',
            depende: ''
        };
        return saleItem;
    }

    private static createSaleItemFromSaleItem(saleItem: SaleItem) {
        const newSaleItem: SaleItem = {
            id: saleItem.id,
            servico: saleItem.servico,
            produtoInvalido: false,
            quantidade: saleItem.quantidade,
            valorUnitario: saleItem.valorUnitario,
            observacao: saleItem.observacao,
            valorUnitarioMinimo: saleItem.valorUnitarioMinimo,
            odEsferico: saleItem.odEsferico,
            odCilindrico: saleItem.odCilindrico,
            odEixo: saleItem.odEixo,
            odAdicao: saleItem.odAdicao,
            odDnp: saleItem.odDnp,
            odAltura: saleItem.odAltura,
            oeEsferico: saleItem.oeEsferico,
            oeCilindrico: saleItem.oeCilindrico,
            oeEixo: saleItem.oeEixo,
            oeAdicao: saleItem.oeAdicao,
            oeDnp: saleItem.oeDnp,
            oeAltura: saleItem.oeAltura,
            pantoscopicAngle: saleItem.pantoscopicAngle,
            curvatureAngle: saleItem.curvatureAngle,
            croRightEye: saleItem.croRightEye,
            croLeftEye: saleItem.croLeftEye,
            readingDistance: saleItem.readingDistance,
            dominantEye: saleItem.dominantEye,
            coefficientHeadEye: saleItem.coefficientHeadEye,
            numeroItem: saleItem.numeroItem,
            depende: saleItem.depende
        };
        return newSaleItem;
    }

    static async graficoEntradaDentroDoDeterminado(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + '/grafico/entradaDentroDoDeterminado', {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        });
    }

    static async graficoValorVendido(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + '/grafico/valorVendido', {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        });
    }

    static async graficoCurvaAbc(dataInicial: string, dataFinal: string, vendas: boolean, porProduto: boolean) {
        return AppService.getAuthorized(this.API_URL + '/grafico/curvaAbc', {
            dataInicial: dataInicial,
            dataFinal: dataFinal,
            vendas: vendas,
            porProduto: porProduto
        });
    }

    static async produtosDeVendasFechadasPorHora(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + '/grafico/produtosDeVendasFechadasPorHora', {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        });
    }

    static async graficoValorVendidoPorVendedor(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + '/grafico/valorVendidoPorVendedor', {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        });
    }

    static async graficoValorVendidoPorCategoria(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + '/grafico/valorVendidoPorCategoria', {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        });
    }

    static async graficoQuantidadeVendidaPorCategoria(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + '/grafico/quantidadeVendidaPorCategoria', {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        });
    }

    static async graficoValorVendidoPorQuantidadeDeParcelas(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + '/grafico/valorVendidoPorQuantidadeDeParcelas', {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        });
    }

    static async graficoValorMedio(dataInicial: string, dataFinal: string) {
        return AppService.getAuthorized(this.API_URL + '/grafico/valorMedio', {
            dataInicial: dataInicial,
            dataFinal: dataFinal
        });
    }

    static async graficoRoiInstagram(startDate: string, endDate: string, category: Category) {
        let categoryId = null;
        if (category && category.id) {
            categoryId = category.id;
        }
        return AppService.getAuthorized(this.API_URL + '/grafico/roiInstagram', {
            startDate: startDate,
            endDate: endDate,
            category: categoryId
        });
    }

    static async getSalesPerTime(startDate: string, endDate: string) {
        return AppService.getAuthorized(this.API_URL + '/grafico/vendasPorTempo', {
            startDate: startDate,
            endDate: endDate
        });
    }

    static getProfile = (startDate: string, endDate: string) => {
        return AppService.getAuthorized(this.API_URL + '/perfil', {
            dataInicial: startDate,
            dataFinal: endDate
        });
    }

    static async getAnalise(startDate: string, startHour: string, endDate: string, endHour: string, seller: number) {
        return AppService.getAuthorized(this.API_URL + '/analise', {
            dataInicial: startDate,
            horaInicial: startHour,
            dataFinal: endDate,
            horaFinal: endHour,
            vendedor: seller
        });
    }

    static ultimaCompra(startDate: string, endDate: string) {
        return AppService.getAuthorized(this.API_URL + "/ultimaCompra", {
            dataInicial: startDate,
            dataFinal: endDate
        }, "arraybuffer");
    }

    static isLens(saleItem: SaleItem) {
        return !!saleItem.servico?.categoria?.lente;
    }

    static hasLens(sale: Sale) {
        let has = false;
        for (let i = 0; i < sale.itens.length; i++) {
            if (this.isLens(sale.itens[i])) {
                has = true;
                break;
            }
        }
        return has;
    }

    static getNumeroProximoItem(itens: SaleItem[]) {
        let maiorNumero = 0;
        itens.forEach(function (item) {
            if (!item.numeroItem) {
                throw new Error("NumeroItem required");
            }
            if (item.numeroItem > maiorNumero) {
                maiorNumero = item.numeroItem;
            }
        });
        return maiorNumero + 1;
    }

    static createEntrada = (): Entrada => {
        return {
            date: DateUtil.toJavaSqlDate(DateUtil.currentDate()),
            value: 0,
            installmentOption: null,
            cardBrand: null,
            cardNumberOfParcels: ""
        }
    }

}

export {SaleService};
