import React, {useEffect, useState} from "react";
import {changePageTitle} from "../typescript/redux/actions";
import {useDispatch} from "react-redux";
import {
    CentroDeCustoDetail,
    ForeignKey
} from "../index";
import {DocumentService} from "./document.service";
import {useNavigate} from "react-router-dom";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {PersonService} from "../person/person.service";
import {CentroDeCustoService} from "../centro-de-custo/centro-de-custo.service";

export function DocumentParcels(props) {

    let navigate = useNavigate();

    const [valorTotal, setValorTotal] = useState("");
    const [parcelas, setParcelas] = useState("");
    const [dia, setDia] = useState("");
    const [mes, setMes] = useState("");
    const [ano, setAno] = useState("");
    const [pessoa, setPessoa] = useState(PersonService.createPerson());
    const [centroDeCusto, setCentroDeCusto] = useState(CentroDeCustoService.createCentroDeCusto());

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changePageTitle("Gerar parcelas à pagar"));
    }, [dispatch]);

    const generate = (event) => {
        event.preventDefault();
        event.stopPropagation();
        DocumentService.generateParcels(valorTotal, parcelas, dia, mes, ano, pessoa, centroDeCusto).then((result) => {
            if (props.onClose) {
                props.onClose(result.data);
            } else {
                back();
            }
        }).catch(() => {

        })
    }

    const back = () => {
        navigate('/documents');
    }

    const onChangePessoa = async (id) => {
        setPessoa({
            id: id
        });
        setCentroDeCusto(CentroDeCustoService.createCentroDeCusto());
        if (id) {
            const pessoa = await PersonService.findOne(id);
            if (pessoa) {
                let centroDeCusto = CentroDeCustoService.createCentroDeCusto();
                if (pessoa.centrodecusto) {
                    centroDeCusto = pessoa.centrodecusto;
                }
                setPessoa(pessoa);
                setCentroDeCusto(centroDeCusto);
            }
        }
    }

    const onSelectPessoa = async (pessoa) => {
        let centroDeCusto = CentroDeCustoService.createCentroDeCusto();
        if (pessoa.centrodecusto) {
            centroDeCusto = await CentroDeCustoService.findOne(pessoa.centrodecusto);
        }
        if (pessoa) {
            setPessoa(pessoa);
            setCentroDeCusto(centroDeCusto);
        }
    }

    const findPessoas = (page, size, sort, filter) => {
        return PersonService.findAll(page, size, sort, filter);
    }


    const onChangeCentroDeCusto = async (id) => {
        setCentroDeCusto({
            id: id
        });
        if (id) {
            const centroDeCusto = await CentroDeCustoService.findOne(id);
            if (centroDeCusto) {
                setCentroDeCusto(centroDeCusto);
            }
        }
    }

    const onAddCentroDeCusto = (centroDeCusto) => {
        if (centroDeCusto) {
            setCentroDeCusto(centroDeCusto);
        }
    }

    const findCentroDeCustos = (page, size, sort, filter) => {
        return CentroDeCustoService.findAll(page, size, sort, filter);
    }

    return (
        <form onSubmit={generate}>
            <CustomTextField type="number" fullWidth required label="Valor total (R$)" value={valorTotal}
                             onChange={(event) => setValorTotal(event.target.value)}/>
            <CustomTextField type="number" fullWidth required label="Parcelas" value={parcelas}
                             onChange={(event) => setParcelas(event.target.value)}/>

            <CustomTextField type="number" fullWidth required label="Dia do vencimento" value={dia}
                             onChange={(event) => setDia(event.target.value)}/>
            <CustomTextField type="number" fullWidth required label="Mês da primeira parcela" value={mes}
                             onChange={(event) => setMes(event.target.value)}/>
            <CustomTextField type="number" fullWidth required label="Ano da primeira parcela" value={ano}
                             onChange={(event) => setAno(event.target.value)}/>
            <ForeignKey fullWidth required label="Pessoa" value={pessoa} fieldKey={'id'}
                        fieldDescription={'nome'} labelDescription='Nome' onChange={onChangePessoa}
                        onSelect={onSelectPessoa} findAll={findPessoas}
                        dataColumnNames={['ID', 'Nome']} dataColumns={['id', 'nome']}
                        dataTypes={['number', 'text']}/>
            <ForeignKey fullWidth required label="Tipo de gasto" value={centroDeCusto} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCentroDeCusto}
                        onSelect={onAddCentroDeCusto} onAdd={onAddCentroDeCusto} findAll={findCentroDeCustos}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']} addComponent={<CentroDeCustoDetail/>}/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Gerar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={back}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}