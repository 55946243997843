import React, {useState} from "react";
import {IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import {ButtonSelectItem} from "./button-select-item";
import {ColorIconEnum} from "../util/color-icon-enum";

interface ButtonSelectProps {
    items: ButtonSelectItem[];
    id: number | string | null;
    onChange: (id: number | string | null) => void;
    title: string;
    disabled?: boolean;
}

const ButtonSelect = React.memo(({items, id, onChange, title, disabled}: ButtonSelectProps) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderIcon = () => {
        const itemSelected = items.filter(item => item.id === id);
        if (itemSelected[0]) {
            return itemSelected[0].icon;
        }
    }

    const renderColor = (): ColorIconEnum => {
        const itemSelected = items.filter(item => item.id === id);
        if (itemSelected[0]) {
            return itemSelected[0].color;
        }
        return ColorIconEnum.INHERIT
    }

    const onClickMenuItem = (event: React.MouseEvent<HTMLElement>, item: ButtonSelectItem) => {
        event.stopPropagation();
        handleClose();
        onChange(item.id);
    }

    return (
        <React.Fragment>
            <Tooltip title={title}>
                    <span>
                        <IconButton color={renderColor()} size={"small"} onClick={handleClick} aria-haspopup="true"
                                    disabled={disabled}>
                            {renderIcon()}
                        </IconButton>
                    </span>
            </Tooltip>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {items.map((item, index) => {
                    return (
                        <MenuItem key={index} selected={id === item.id}
                                  onClick={(event) => onClickMenuItem(event, item)}>{item.descricao || item.description}</MenuItem>
                    )
                })}
            </Menu>
        </React.Fragment>
    )
});

export default ButtonSelect;
