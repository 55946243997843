import React, {useCallback, useEffect, useState} from "react";
import {SaleService} from "../sale/sale.service";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {useDispatch} from "react-redux";
import {Datatable} from "../index";
import SaleAfterListForm from "./sale-after-list-form";
import {Edit, GetApp, LocalShipping, Payment, Publish, ThumbDown, ThumbUp, Warning, Phone} from "@mui/icons-material";
import {IconButton, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {AuthService} from "../typescript/auth/auth.service";
import ButtonSelect from "../typescript/button-select/button-select";

export function SaleAfterList() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [items, setItems] = useState(SaleService.createSalesClosedResponse());
    const isMaster = AuthService.userInRole("MASTER");

    const findAll = useCallback(async (startDateParam, endDateParam, idParam, personNameParam) => {
        const result = await SaleService.findAllClosed(startDateParam, endDateParam, idParam, personNameParam);
        setItems(result);
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Pós Vendas (Só aparecem vendas fechadas)"));
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const delivery = (item) => {
        SaleService.delivery(item.id).then(() => {
            dispatch(setAlert({show: true, message: 'Venda entregue com sucesso', severity: 'success'}));
            goToReceivables(item.id);
        }).catch((error) => {
            dispatch(setAlert({show: true, message: 'Erro ao entregar venda - ' + error.data.message, severity: 'error'}));
        })
    }

    const advice = (item) => {
        SaleService.advice(item.id).then(() => {
            dispatch(setAlert({show: true, message: 'Venda avisada com sucesso', severity: 'success'}));
            updateItemAdviced(item);
        }).catch((error) => {
            dispatch(setAlert({show: true, message: 'Erro ao avisar venda - ' + error.data.message, severity: 'error'}));
        })
    }

    const cancelDelivery = (item) => {
        SaleService.cancelDelivery(item.id).then(() => {
            dispatch(setAlert({show: true, message: 'Entrega cancelada com sucesso', severity: 'success'}));
            updateItemDelivered(item);
        }).catch((error) => {
            dispatch(setAlert({show: true, message: 'Erro ao cancelar entrega - ' + error.data.message, severity: 'error'}));
        })
    }

    const cancelAdvice = (item) => {
        SaleService.cancelAdvice(item.id).then(() => {
            dispatch(setAlert({show: true, message: 'Aviso cancelado com sucesso', severity: 'success'}));
            updateItemAdviced(item);
        }).catch((error) => {
            dispatch(setAlert({show: true, message: 'Erro ao cancelar aviso - ' + error.data.message, severity: 'error'}));
        })
    }

    const updateItemReceipt = (item) => {
        let salesToChange = [...items.sales];
        for (let saleToChange of salesToChange) {
            if (saleToChange.id === item.id) {
                saleToChange.receipt = !saleToChange.receipt;
                break;
            }
        }
        setItems({
            ...items,
            sales: salesToChange
        })
    }

    const updateItemDelivered = (item) => {
        let salesToChange = [...items.sales];
        for (let saleToChange of salesToChange) {
            if (saleToChange.id === item.id) {
                saleToChange.delivery = !saleToChange.delivery;
                break;
            }
        }
        setItems({
            ...items,
            sales: salesToChange
        })
    }

    const updateItemAdviced = (item) => {
        let salesToChange = [...items.sales];
        for (let saleToChange of salesToChange) {
            if (saleToChange.id === item.id) {
                saleToChange.advice = !saleToChange.advice;
                break;
            }
        }
        setItems({
            ...items,
            sales: salesToChange
        })
    }

    const goToReceivables = (saleId) => {
        navigate('/sales/after/' + saleId + '/receivables');
    }

    const goToPayables = (saleId) => {
        navigate('/sales/after/' + saleId + '/payables');
    }

    const receipt = (item) => {
        SaleService.recebe(item.id).then(() => {
            dispatch(setAlert({show: true, message: 'Venda recebida com sucesso', severity: 'success'}));
            if (isMaster) {
                goToPayables(item.id);
            } else {
                updateItemReceipt(item);
            }
        }).catch((error) => {
            dispatch(setAlert({show: true, message: 'Erro ao receber venda - ' + error.data.message, severity: 'error'}));
        })
    }

    const cancelReceipt = (item) => {
        SaleService.cancelaRecebimento(item.id).then(() => {
            dispatch(setAlert({show: true, message: 'Recebimento cancelado com sucesso', severity: 'success'}));
            updateItemReceipt(item);
        }).catch((error) => {
            dispatch(setAlert({show: true, message: 'Erro ao cancelar recebimento - ' + error.data.message, severity: 'error'}));
        })
    }

    const status3 = [
        {id: null, descricao: 'Neutro', icon: <Edit/>, color: "error"},
        {id: 'SE_ADAPTOU_BEM', descricao: 'Se adaptou bem', icon: <ThumbUp/>, color: "primary"},
        {id: 'AINDA_NAO_SE_ADAPTOU', descricao: 'Ainda não se adaptou', icon: <Warning/>, color: "warning"},
        {id: 'NAO_SE_ADAPTOU', descricao: 'Não se adaptou', icon: <ThumbDown/>, color: "error"},
        {id: 'VAI_LEVAR_AO_MEDICO', descricao: 'Vai levar ao médico', icon: <Warning/>, color: "warning"}
    ]

    const onChangeStatus3 = async (newStatus3, item) => {
        SaleService.updateStatus3(item.id, newStatus3).then(function (result) {
            let itemsToChange = {...items};
            for (let i = 0; i < itemsToChange.sales.length; i++) {
                if (itemsToChange.sales[i].id === item.id) {
                    itemsToChange.sales[i] = result.data;
                    break;
                }
            }
            setItems(itemsToChange);
        }, (error) => {
            dispatch(setAlert({show: true, message: error.data.message, severity: 'error'}));
        });
    }

    const renderDescriptionStatus3 = (id) => {
        const itemSelected = status3.filter(item => item.id === id);
        if (itemSelected[0]) {
            return itemSelected[0].descricao;
        }
    }

    const renderStatus3 = (item, dataColumn) => {
        return (
            <ButtonSelect items={status3} id={item[dataColumn]}
                          onChange={(newStatus3) => onChangeStatus3(newStatus3, item)}
                          title={renderDescriptionStatus3(item[dataColumn])}/>
        )
    }

    const renderPayableDocuments = (item) => {
        return isMaster && (
            <Tooltip color='primary' title={"Documentos a pagar"}>
                <IconButton onClick={() => goToPayables(item.id)}>
                    <Payment/>
                </IconButton>
            </Tooltip>
        )
    }

    const renderReceipt = (item) => {
        if (item.receipt) {
            const colorReceipt = item.hasOpenDocuments ? "warning" : "primary";
            return (
                <Tooltip color={colorReceipt} title={"Cancelar recebimento"}>
                    <IconButton onClick={() => cancelReceipt(item)}>
                        <Publish/>
                    </IconButton>
                </Tooltip>
            )
        }
        const colorDelivery = item.hasOpenDocuments ? "warning" : "error";
        return (
            <Tooltip color={colorDelivery} title={"Receber"}>
                <IconButton onClick={() => receipt(item)}>
                    <GetApp/>
                </IconButton>
            </Tooltip>
        )
    }

    const renderAdvice = (item) => {
        if (item.advice) {
            return (
                <Tooltip color={"primary"} title={"Cancelar aviso"}>
                    <IconButton onClick={() => cancelAdvice(item)}>
                        <Phone/>
                    </IconButton>
                </Tooltip>
            )
        }
        return (
            <Tooltip color={"error"} title={"Avisar"}>
                <IconButton onClick={() => advice(item)}>
                    <Phone/>
                </IconButton>
            </Tooltip>
        )
    }

    const renderDelivery = (item) => {
        if (item.delivery) {
            return (
                <Tooltip color={"primary"} title={"Cancelar entrega"}>
                    <IconButton onClick={() => cancelDelivery(item)}>
                        <LocalShipping/>
                    </IconButton>
                </Tooltip>
            )
        }
        return (
            <Tooltip color={"error"} title={"Entregar"}>
                <IconButton onClick={() => delivery(item)}>
                    <LocalShipping/>
                </IconButton>
            </Tooltip>
        )
    }

    const renderPayableColumn = () => {
        return isMaster ? 'Doc a pagar' : null;
    }

    return (
        <React.Fragment>
            <SaleAfterListForm findAll={findAll}/>
            <Datatable data={items.sales} totalData={items.sales.length}
                       dataColumnNames={['ID Venda', 'Cliente', 'Telefone', 'Emissão', 'Total', 'Data/hora fechamento', renderPayableColumn(), 'Etapa 1 (Receber)', 'Etapa 2 (Avisar)', 'Etapa 3 (Entregar)', 'Etapa 4 (Status)']}
                       dataColumns={['id', 'person.name', 'person.phone', 'created_date', 'total', 'finished', '', '', '', '', 'status3']}
                       dataTypes={['number', 'text', 'text', 'date', 'currency', 'zonedatetime', 'text', 'text', 'text', 'text', 'enum']} findAll={findAll}
                       dataValues={[null, null, null, null, null, null, renderPayableDocuments, renderReceipt, renderAdvice, renderDelivery, renderStatus3]}
                       summarize={[null, null, null, null, 'total', null, null, null, null, null, null]}/>
        </React.Fragment>
    );

}
