import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Provider} from "react-redux";
import appRoutes from "./components/app/app-routes";
import {store} from "./components/typescript/redux/store";

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter(appRoutes);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);