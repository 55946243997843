import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ParameterService} from "./parameter.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import CustomSwitch from "../typescript/custom-switch/custom-switch";

const ParameterDetail = () => {
    const dispatch = useDispatch();
    const [parameter, setParameter] = useState(null);

    const getParameter = useCallback(async () => {
        const result = await ParameterService.getAll();
        setParameter(result);
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Parâmetros"));
        getParameter().then();
    }, [dispatch, getParameter]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        ParameterService.save(parameter).then(() => {
            getParameter().then();
            dispatch(setAlert({
                show: true,
                message: 'Parâmetros salvos com sucesso',
                severity: 'success'
            }));
        }, (error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao salvar parâmetros - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        getParameter().then();
        dispatch(setAlert({show: true, message: 'Alterações canceladas', severity: 'error'}));
    }

    const onChangeRazaoSocial = (event) => {
        setParameter({
            ...parameter,
            corporateName: event.target.value
        });
    }

    const onChangeLogradouro = (event) => {
        setParameter({
            ...parameter,
            street: event.target.value
        });
    }

    const onChangeNumero = (event) => {
        setParameter({
            ...parameter,
            number: event.target.value
        });
    }

    const onChangeBairro = (event) => {
        setParameter({
            ...parameter,
            neighborhood: event.target.value
        });
    }

    const onChangeCep = (event) => {
        setParameter({
            ...parameter,
            zipCode: event.target.value
        });
    }

    const onChangeCidade = (event) => {
        setParameter({
            ...parameter,
            city: event.target.value
        });
    }

    const onChangeTelefone = (event) => {
        setParameter({
            ...parameter,
            phone: event.target.value
        });
    }

    const onChangeEmail = (event) => {
        setParameter({
            ...parameter,
            email: event.target.value
        });
    }

    const onChangeCnpj = (event) => {
        setParameter({
            ...parameter,
            cnpj: event.target.value
        });
    }

    const onChangeInscricaoEstadual = (event) => {
        setParameter({
            ...parameter,
            stateRegistration: event.target.value
        });
    }

    const onChangeMulta = (event) => {
        setParameter({
            ...parameter,
            finePercentage: event.target.value
        });
    }

    const onChangeJuros = (event) => {
        setParameter({
            ...parameter,
            feesDayPercentage: event.target.value
        });
    }

    const onChangeLucro = (event) => {
        setParameter({
            ...parameter,
            profitPercentage: event.target.value
        });
    }

    const onChangeDescontoMaximo = (event) => {
        setParameter({
            ...parameter,
            maximumDiscount: event.target.value
        });
    }

    const onChangeUsaNfeEmAmbienteProducao = (event) => {
        setParameter({
            ...parameter,
            useNfeProductionEnvironment: event.target.checked
        });
    }

    const onChangeNumeroProximaNotaSerie1 = (event) => {
        setParameter({
            ...parameter,
            numberNextInvoiceSeries1: event.target.value
        });
    }

    const onChangeNumeroProximaNotaSerie2 = (event) => {
        setParameter({
            ...parameter,
            numberNextInvoiceSeries2: event.target.value
        });
    }

    const onChangeSerie = (event) => {
        setParameter({
            ...parameter,
            series: event.target.value
        });
    }

    const onChangeSenhaCertificado = (event) => {
        setParameter({
            ...parameter,
            passwordCertificate: event.target.value
        });
    }

    const onChangeSenhaCadeiaCertificados = (event) => {
        setParameter({
            ...parameter,
            passwordCertificationChain: event.target.value
        });
    }

    const onChangeCscIdHomologacao = (event) => {
        setParameter({
            ...parameter,
            cscIdHomologation: event.target.value
        });
    }

    const onChangeCscHomologacao = (event) => {
        setParameter({
            ...parameter,
            cscHomologation: event.target.value
        });
    }

    const onChangeCscIdProducao = (event) => {
        setParameter({
            ...parameter,
            cscIdProduction: event.target.value
        });
    }

    const onChangeCscProducao = (event) => {
        setParameter({
            ...parameter,
            cscProduction: event.target.value
        });
    }

    const onChangeClientePadrao = (event) => {
        setParameter({
            ...parameter,
            defaultCustomer: event.target.value
        });
    }

    const onChangeCarteiraCaixa = (event) => {
        setParameter({
            ...parameter,
            walletCash: event.target.value
        });
    }

    const onChangeCarteiraSangria = (event) => {
        setParameter({
            ...parameter,
            walletBleed: event.target.value
        });
    }

    const onChangeCentroDeCustoDiferencaCaixa = (event) => {
        setParameter({
            ...parameter,
            costCenterDifferenceCash: event.target.value
        });
    }

    const onChangeCentroDeCustoSangria = (event) => {
        setParameter({
            ...parameter,
            costCenterBleed: event.target.value
        });
    }

    const onChangePessoaDiferencaCaixa = (event) => {
        setParameter({
            ...parameter,
            personDifferenceCash: event.target.value
        });
    }

    const onChangePessoaSangria = (event) => {
        setParameter({
            ...parameter,
            personBleed: event.target.value
        });
    }

    const onChangeModeloFiscal = (event) => {
        setParameter({
            ...parameter,
            fiscalModel: event.target.value
        });
    }

    return parameter && (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Razão social" value={parameter.corporateName}
                             onChange={onChangeRazaoSocial}/>
            <CustomTextField fullWidth required label="Logradouro" value={parameter.street}
                             onChange={onChangeLogradouro}/>
            <CustomTextField fullWidth required label="Número" value={parameter.number} onChange={onChangeNumero}/>
            <CustomTextField fullWidth required label="Bairro" value={parameter.neighborhood}
                             onChange={onChangeBairro}/>
            <CustomTextField fullWidth required label="Cep" type="number" value={parameter.zipCode}
                             onChange={onChangeCep}/>
            <CustomTextField fullWidth required label="Cidade" type="number" value={parameter.city}
                             onChange={onChangeCidade}/>
            <CustomTextField fullWidth required label="Telefone" value={parameter.phone}
                             onChange={onChangeTelefone}/>
            <CustomTextField fullWidth required label="Email" type={"email"} value={parameter.email}
                             onChange={onChangeEmail}/>
            <CustomTextField fullWidth required label="CNPJ" value={parameter.cnpj} onChange={onChangeCnpj}/>
            <CustomTextField fullWidth required label="Inscrição Estadual" value={parameter.stateRegistration}
                             onChange={onChangeInscricaoEstadual}/>
            <CustomTextField fullWidth label="Multa (%)" type="number" value={parameter.finePercentage || ''}
                             onChange={onChangeMulta}/>
            <CustomTextField fullWidth label="Juros por dia (%)" type="number"
                             value={parameter.feesDayPercentage || ''}
                             onChange={onChangeJuros}/>
            <CustomTextField fullWidth label="Lucro (%)" type="number" value={parameter.profitPercentage || ''}
                             onChange={onChangeLucro}/>
            <CustomTextField fullWidth label="Desconto máximo (%)" type="number" value={parameter.maximumDiscount || ''}
                             onChange={onChangeDescontoMaximo}/>
            <CustomSwitch title={"Usa NF-e em ambiente de produção"} on={"Sim"} off={"Não"}
                          checked={parameter.useNfeProductionEnvironment} onChange={onChangeUsaNfeEmAmbienteProducao}/>
            <CustomTextField fullWidth required label="Número da próxima nota (Série 1)" type="number"
                             value={parameter.numberNextInvoiceSeries1}
                             onChange={onChangeNumeroProximaNotaSerie1}/>
            <CustomTextField fullWidth required label="Número da próxima nota (Série 2)" type="number"
                             value={parameter.numberNextInvoiceSeries2}
                             onChange={onChangeNumeroProximaNotaSerie2}/>
            <CustomTextField fullWidth required label="Série" type="number" value={parameter.series}
                             onChange={onChangeSerie}/>
            <CustomTextField fullWidth required label="Senha do certificado" value={parameter.passwordCertificate}
                             onChange={onChangeSenhaCertificado}/>
            <CustomTextField fullWidth required label="Senha da cadeia de certificados"
                             value={parameter.passwordCertificationChain} onChange={onChangeSenhaCadeiaCertificados}/>
            <CustomTextField fullWidth required label="CSC ID - ambiente de homologação"
                             value={parameter.cscIdHomologation}
                             onChange={onChangeCscIdHomologacao}/>
            <CustomTextField fullWidth required label="CSC - ambiente de homologação" value={parameter.cscHomologation}
                             onChange={onChangeCscHomologacao}/>
            <CustomTextField fullWidth required label="CSC ID - ambiente de produção" value={parameter.cscIdProduction}
                             onChange={onChangeCscIdProducao}/>
            <CustomTextField fullWidth required label="CSC - ambiente de produção" value={parameter.cscProduction}
                             onChange={onChangeCscProducao}/>
            <CustomTextField fullWidth label="Cliente padrão" value={parameter.defaultCustomer || ''}
                             onChange={onChangeClientePadrao}/>
            <CustomTextField fullWidth required label="Carteira caixa" value={parameter.walletCash}
                             onChange={onChangeCarteiraCaixa}/>
            <CustomTextField fullWidth required label="Carteira sangria" value={parameter.walletBleed}
                             onChange={onChangeCarteiraSangria}/>
            <CustomTextField fullWidth required label="Tipo de gasto diferença caixa"
                             value={parameter.costCenterDifferenceCash}
                             onChange={onChangeCentroDeCustoDiferencaCaixa}/>
            <CustomTextField fullWidth required label="Tipo de gasto sangria" value={parameter.costCenterBleed}
                             onChange={onChangeCentroDeCustoSangria}/>
            <CustomTextField fullWidth required label="Pessoa diferença caixa" value={parameter.personDifferenceCash}
                             onChange={onChangePessoaDiferencaCaixa}/>
            <CustomTextField fullWidth required label="Pessoa sangria" value={parameter.personBleed}
                             onChange={onChangePessoaSangria}/>
            <CustomTextField fullWidth required label="Modelo fiscal padrão" value={parameter.fiscalModel}
                             onChange={onChangeModeloFiscal}/>

            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    );
}

export default React.memo(ParameterDetail);