import React, {FormEvent, useEffect, useState} from "react";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import SelectOption from "../typescript/select-option/select-option";
import {ParenteService} from "./parente.service";
import {Parente} from "./parente";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";

interface ParenteDetailProps {
    parent: Parente;
    onClose?: (parent?: Parente) => void;
}

export function ParenteDetail({parent, onClose}: ParenteDetailProps) {

    const [item, setItem] = useState<Parente>(ParenteService.createParente());

    useEffect(() => {
        setItem(parent);
    }, [parent]);

    const save = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (onClose) {
            onClose(item);
        }
    }

    const cancel = () => {
        if (onClose) {
            onClose();
        }
    }

    const setNome = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            nome: event.target.value
        })
    }

    const setTelefone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            telefone: event.target.value
        })
    }

    const setDataNascimento = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            dataNascimento: event.target.value
        })
    }

    const setGrauDeParentesco = (event: SelectChangeEvent<any[] | any>) => {
        setItem({
            ...item,
            grau: event.target.value
        })
    }

    const parenteOptions = [
        {name: 'PAI', description: 'Pai'},
        {name: 'MAE', description: 'Mãe'},
        {name: 'FILHO', description: 'Filho'},
        {name: 'FILHA', description: 'Filha'},
        {name: 'IRMAO', description: 'Irmão'},
        {name: 'IRMA', description: 'Irmã'},
        {name: 'AVO1', description: 'Avô'},
        {name: 'AVO2', description: 'Avó'},
        {name: 'TIO', description: 'Tio'},
        {name: 'TIA', description: 'Tia'},
        {name: 'NETO', description: 'Neto'},
        {name: 'NETA', description: 'Neta'},
        {name: 'BISNETO', description: 'Bisneto'},
        {name: 'BISNETA', description: 'Bisneta'},
        {name: 'BISAVO1', description: 'Bisavô'},
        {name: 'BISAVO2', description: 'Bisavó'}];

    return (
        <form onSubmit={save}>
            <CustomTextField fullWidth autoFocus required label="Nome" value={item.nome} onChange={setNome}/>
            <CustomTextField fullWidth required label="Telefone" value={item.telefone} onChange={setTelefone}/>
            <CustomTextField fullWidth required label="Data de nascimento" type="date" value={item.dataNascimento || ''}
                             InputLabelProps={{shrink: true}} onChange={setDataNascimento}/>
            <SelectOption required fullWidth label="Grau de parentesco" value={item.grau || ''}
                          onChange={setGrauDeParentesco} items={parenteOptions} itemValue='name'
                          itemDescription='description'/>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}
