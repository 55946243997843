import React from "react";
import ButtonSelect from "../../../../button-select/button-select";
import {AttachMoney, Cancel, Check, Edit, Facebook, Instagram} from "@mui/icons-material";
import {ColorIconEnum} from "../../../../util/color-icon-enum";
import {AuthService} from "../../../../auth/auth.service";
import {SaleService} from "../../../../../sale/sale.service";
import {setAlert} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {Sale} from "../../../../../sale/sale";

interface SaleListBodyTableContentRowStatus2Props {
    sale: Sale;
    userId: number;
    findAll: () => void;
}

const SaleListBodyTableContentRowStatus2 = React.memo(({
                                                           sale,
                                                           userId,
                                                           findAll
                                                       }: SaleListBodyTableContentRowStatus2Props) => {

    const dispatch = useDispatch();

    const user = AuthService.getUserDetails();
    const isAdmin = AuthService.userInRole("ADMIN");
    const isMaster = AuthService.userInRole("MASTER");
    const hasPermissao = (isAdmin || isMaster || userId === user?.id);

    const temNota = (!!sale.nota);
    const isFechado = (!!sale.dataHoraFechamento);

    const status2Disabled = (!hasPermissao || temNota || isFechado);

    let saleId: number;
    if (sale.id) {
        saleId = sale.id;
    } else {
        throw new Error('Sale id required');
    }

    const onChangeStatus2 = async (newStatus2: number | string | null) => {
        if (!saleId) return;
        const statusParam = newStatus2 ? newStatus2.toString() : undefined;
        SaleService.updateStatus2(saleId, statusParam).then(function () {
            findAll();
        }, (error) => {
            dispatch(setAlert({show: true, message: error.data.message, severity: 'error'}));
        });
    }

    const status2Items = [
        {id: null, descricao: 'Neutro', icon: <Edit/>, color: ColorIconEnum.DEFAULT},
        {id: 'COMPROU', descricao: 'Comprou', icon: <Check/>, color: ColorIconEnum.PRIMARY},
        {id: 'FEZ_ORCAMENTO', descricao: 'Fez orçamento', icon: <AttachMoney/>, color: ColorIconEnum.PRIMARY},
        {id: 'FEZ_EM_OUTRO_LUGAR', descricao: 'Fez em outro lugar', icon: <Cancel/>, color: ColorIconEnum.ERROR},
        {id: 'NAO_PRECISOU', descricao: 'Não precisou', icon: <Cancel/>, color: ColorIconEnum.ERROR},
        {id: 'ACHOU_CARO', descricao: 'Achou caro', icon: <Cancel/>, color: ColorIconEnum.ERROR},
        {id: 'ORCAMENTO_WHATTS', descricao: 'Orçamento whatts', icon: <AttachMoney/>, color: ColorIconEnum.PRIMARY},
        {id: 'FALTOU_ARMACAO', descricao: 'Faltou armação', icon: <Cancel/>, color: ColorIconEnum.ERROR},
        {id: 'NAO_RESPONDEU', descricao: 'Não respondeu mais', icon: <Cancel/>, color: ColorIconEnum.ERROR},
        {id: 'CONDICAO_DE_PAGAMENTO', descricao: 'Condição de pagamento', icon: <AttachMoney/>, color: ColorIconEnum.ERROR},
        {id: 'TRAFEGO_INSTA', descricao: 'Tráfego insta', icon: <Instagram/>, color: ColorIconEnum.PRIMARY},
        {id: 'TRAFEGO_FACE', descricao: 'Tráfego face', icon: <Facebook/>, color: ColorIconEnum.PRIMARY}
    ]

    const renderDescriptionStatus2 = () => {
        const itemSelected = status2Items.filter(item => item.id === sale.status2);
        if (itemSelected[0]) {
            return itemSelected[0].descricao;
        }
        throw new Error('Status2 unknown');
    }

    return (
        <ButtonSelect items={status2Items} id={sale.status2 || null} onChange={onChangeStatus2}
                      title={renderDescriptionStatus2()} disabled={status2Disabled}/>
    )
});

export default SaleListBodyTableContentRowStatus2;
