import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Checkbox, FormControlLabel, Paper, Tab, Tabs} from "@mui/material";
import {CategoryDetail, Datatable, ForeignKey} from "../index";
import {ProductService} from "./product.service";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {ValorDetail} from "./valor-detail";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {CustomDialog} from "../typescript/custom-dialog/custom-dialog";
import {AuthService} from "../typescript/auth/auth.service";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import SelectOption from "../typescript/select-option/select-option";
import {CategoryService} from "../category/category.service";
import {ColorService} from "../color/color.service";

const classes = {
    productDetailTabBar: {
        marginBottom: '8px'
    }
};

export function ProductDetail({id, onClose, dialog}) {

    const [item, setItem] = useState(ProductService.createProduct());
    const [tabValue, setTabValue] = React.useState(0);
    const [showValorDialog, setShowValorDialog] = useState(false);
    const [valorToModal, setValorToModal] = useState({item: ProductService.createValor(), index: -1});
    const [material, setMaterial] = useState(null);
    const isMaster = AuthService.userInRole("MASTER");

    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await ProductService.findOne(id);
        setItem(result);
        const selectedMaterial = ProductService.getMaterials(result);
        setMaterial(selectedMaterial);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Produto " + id : "Novo produto"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        ProductService.setMaterials(item, material);
        ProductService.save(item).then((result) => {
            onClose(result.data);
        }).catch((error) => {
            if (error.response.status === 403) {
                dispatch(setAlert({
                    show: true,
                    message: 'Erro ao salvar produto - Alteração não permitida',
                    severity: 'error'
                }));
            } else {
                let message = 'Erro ao salvar produto';
                if (error.response.data.message) {
                    message += ' - ' + error.response.data.message;
                }
                dispatch(setAlert({show: true, message: message, severity: 'error'}));
            }
        })
    }

    const cancel = () => {
        onClose();
    }

    const setDescricao = (event) => {
        setItem({
            ...item,
            descricao: event.target.value
        })
    }

    const setObservacao = (event) => {
        setItem({
            ...item,
            observacao: event.target.value
        })
    }

    const setUnidade = (event) => {
        setItem({
            ...item,
            unidade: event.target.value
        })
    }

    const setNCM = (event) => {
        setItem({
            ...item,
            ncm: event.target.value
        })
    }

    const setPrazoEntrega = (event) => {
        setItem({
            ...item,
            prazoEntrega: event.target.value
        })
    }

    const onChangeCategoria = async (idCategory) => {
        setItem({
            ...item,
            categoria: {
                id: idCategory
            }
        })
        if (idCategory) {
            const categoria = await CategoryService.findOne(idCategory);
            onAddCategoria(categoria);
        }
    }

    const onAddCategoria = (categoria) => {
        if (categoria) {
            setItem({
                ...item,
                categoria: categoria,
                odCilindricoMin: null,
                odCilindricoMax: null,
                oeCilindricoMin: null,
                oeCilindricoMax: null,
                odEsfericoMin: null,
                odEsfericoMax: null,
                oeEsfericoMin: null,
                oeEsfericoMax: null
            })
        }
    }

    const findCategorias = (page, size, sort, filter) => {
        return CategoryService.findAll(page, size, sort, filter);
    }

    const setAtivo = (event) => {
        setItem({
            ...item,
            ativo: event.target.checked
        })
    }

    const setControlaEstoque = (event) => {
        const newControlaEstoque = event.target.checked;
        if (newControlaEstoque) {
            setItem({
                ...item,
                controlaEstoque: newControlaEstoque,
                estoqueMinimo: 1,
                estoqueIdeal: 1
            })
        } else {
            setItem({
                ...item,
                controlaEstoque: newControlaEstoque,
                estoqueMinimo: '',
                estoqueIdeal: ''
            })
        }
    }

    const setTemClipon = (event) => {
        setItem({
            ...item,
            clipon: event.target.checked
        })
    }

    const setEstoqueAtual = (event) => {
        setItem({
            ...item,
            estoqueAtual: event.target.value
        })
    }

    const setEstoqueMinimo = (event) => {
        setItem({
            ...item,
            estoqueMinimo: event.target.value
        })
    }

    const setEstoqueIdeal = (event) => {
        setItem({
            ...item,
            estoqueIdeal: event.target.value
        })
    }

    const renderEstoque = () => {
        return item.controlaEstoque && (
            <CustomRow>
                <CustomTextField fullWidth required label="Estoque atual" value={item.estoqueAtual || "0"}
                                 onChange={setEstoqueAtual} disabled={!!item.id}/>
                <CustomTextField fullWidth required label="Estoque mínimo" value={item.estoqueMinimo || ""}
                                 onChange={setEstoqueMinimo}/>
                <CustomTextField fullWidth required label="Estoque ideal" value={item.estoqueIdeal || ""}
                                 onChange={setEstoqueIdeal}/>
            </CustomRow>
        )
    }

    const deleteValor = (valorToDelete, index) => {
        let {valores} = item;
        valores.splice(index, 1);
        setItem({
            ...item,
            valores: valores
        })
    }

    const rowOperationsValores = [
        {color: () => "primary", onClick: deleteValor, label: () => 'Excluir', icon: () => <DeleteIcon/>},
    ]

    const openValorDialog = () => {
        setShowValorDialog(true);
    }

    const insertValor = () => {
        setValorToModal({
            item: ProductService.createValor(),
            index: -1
        });
        openValorDialog();
    }

    const editValor = (valorToEdit, index) => {
        setValorToModal({
            item: valorToEdit,
            index: index
        });
        openValorDialog();
    }

    const headerOperationsValores = [
        {color: () => "primary", onClick: insertValor, label: () => 'Inserir'}
    ]

    let valorDataColumnNames;
    let valorDataColumns;
    let valorDataTypes;
    if (AuthService.userInRole("ADMIN")) {
        valorDataColumnNames = ['Fornecedor', 'Custo', 'Valor de venda', 'Data inicial', 'Data final'];
        valorDataColumns = ['fornecedor.nome', 'custo', 'valor', 'dataInicial', 'dataFinal'];
        valorDataTypes = ['text', 'decimal', 'decimal', 'date', 'date'];
    } else {
        valorDataColumnNames = ['Fornecedor', 'Valor de venda', 'Data inicial', 'Data final'];
        valorDataColumns = ['fornecedor.nome', 'valor', 'dataInicial', 'dataFinal'];
        valorDataTypes = ['text', 'decimal', 'date', 'date'];
    }

    function renderValores() {
        return tabValue === 1 && (
            <Datatable data={item.valores} totalData={item.valores.length} rowOperations={rowOperationsValores}
                       dataColumnNames={valorDataColumnNames} dataColumns={valorDataColumns} dataTypes={valorDataTypes}
                       headerOperations={headerOperationsValores} editData={editValor}/>
        )
    }

    const closeValorDialog = (valorEditadoOuInserido) => {
        setShowValorDialog(false);
        if (valorEditadoOuInserido) {
            let {valores} = item;
            if (valorToModal.index !== -1) {
                valores[valorToModal.index] = valorEditadoOuInserido;
            } else {
                valores.push(valorEditadoOuInserido);
            }
            setItem({
                ...item,
                valores: valores
            })
        }
    }

    const renderValorDialog = () => {
        return showValorDialog && (
            <CustomDialog open={showValorDialog} onClose={closeValorDialog} title={'Inserir valor'}><ValorDetail
                item={valorToModal.item}/></CustomDialog>
        )
    }

    const onChangeColor = async (colorId) => {
        setItem({
            ...item,
            color: {
                id: colorId
            }
        })
        if (colorId) {
            const color = await ColorService.findOne(colorId);
            onSelectColor(color);
        }
    }

    const onSelectColor = (color) => {
        if (color) {
            setItem({
                ...item,
                color: color
            })
        }
    }

    const onChangeSize = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            size: newValue
        })
    }

    const onChangeOdCilindricoMin = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            odCilindricoMin: newValue
        })
    }

    const onChangeOdCilindricoMax = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            odCilindricoMax: newValue
        })
    }

    const onChangeOeCilindricoMin = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            oeCilindricoMin: newValue
        })
    }

    const onChangeOeCilindricoMax = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            oeCilindricoMax: newValue
        })
    }

    const onChangeOdEsfericoMin = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            odEsfericoMin: newValue
        })
    }

    const onChangeDiameter = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            diameter: newValue
        })
    }

    const onChangeOdEsfericoMax = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            odEsfericoMax: newValue
        })
    }

    const onChangeOeEsfericoMin = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            oeEsfericoMin: newValue
        })
    }

    const onChangeOeEsfericoMax = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            oeEsfericoMax: newValue
        })
    }

    const onChangeBiggestDiagonal = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            biggestDiagonal: newValue
        })
    }

    const onChangeVertical = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            vertical: newValue
        })
    }

    const onChangeBridge = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            bridge: newValue
        })
    }

    const onChangeLensBaseCurve = (event) => {
        const newValue = event.target.value;
        setItem({
            ...item,
            lensBaseCurve: newValue
        })
    }

    const onChangeMaterial1Color = async (colorId) => {
        setItem({
            ...item,
            material1Color: {
                id: colorId
            }
        })
        if (colorId) {
            const color = await ColorService.findOne(colorId);
            onSelectMaterial1Color(color);
        }
    }

    const onSelectMaterial1Color = (color) => {
        if (color) {
            setItem({
                ...item,
                material1Color: color
            })
        }
    }

    const onChangeMaterial2Color = async (colorId) => {
        setItem({
            ...item,
            material2Color: {
                id: colorId
            }
        })
        if (colorId) {
            const color = await ColorService.findOne(colorId);
            onSelectMaterial2Color(color);
        }
    }

    const onSelectMaterial2Color = (color) => {
        if (color) {
            setItem({
                ...item,
                material2Color: color
            })
        }
    }

    const onChangeMaterial3Color = async (colorId) => {
        setItem({
            ...item,
            material3Color: {
                id: colorId
            }
        })
        if (colorId) {
            const color = await ColorService.findOne(colorId);
            onSelectMaterial3Color(color);
        }
    }

    const onSelectMaterial3Color = (color) => {
        if (color) {
            setItem({
                ...item,
                material3Color: color
            })
        }
    }

    const materialOptions = ProductService.getMaterialOptions();

    const renderMaterialColors = () => {
        switch (material) {
            case 0:
            case 1:
                return renderSelectMaterialColor1();
            case 2:
            case 3:
            case 4:
            case 5:
                return renderSelectMaterialColor12();
            case 6:
                return renderSelectMaterialColor123();
            default:
                return;
        }
    }

    const renderLensDetails = () => {
        return item.categoria && item.categoria.lente && (
            <React.Fragment>
                <CustomRow>
                    <CustomTextField type="number" fullWidth label="OD Esférico Mínimo" value={item.odEsfericoMin}
                                     onChange={onChangeOdEsfericoMin} InputProps={{inputProps: {step: "any"}}}
                                     required={item.categoria.lente}/>
                    <CustomTextField type="number" fullWidth label="OD Esférico Máximo" value={item.odEsfericoMax}
                                     onChange={onChangeOdEsfericoMax} InputProps={{inputProps: {step: "any"}}}
                                     required={item.categoria.lente}/>
                </CustomRow>
                <CustomRow>
                    <CustomTextField type="number" fullWidth label="OE Esférico Mínimo" value={item.oeEsfericoMin}
                                     onChange={onChangeOeEsfericoMin} InputProps={{inputProps: {step: "any"}}}
                                     required={item.categoria.lente}/>
                    <CustomTextField type="number" fullWidth label="OE Esférico Máximo" value={item.oeEsfericoMax}
                                     onChange={onChangeOeEsfericoMax} InputProps={{inputProps: {step: "any"}}}
                                     required={item.categoria.lente}/>
                </CustomRow>
                <CustomRow>
                    <CustomTextField type="number" fullWidth label="OD Cilíndrico Mínimo" value={item.odCilindricoMin}
                                     onChange={onChangeOdCilindricoMin} InputProps={{inputProps: {step: "any"}}}
                                     required={item.categoria.lente}/>
                    <CustomTextField type="number" fullWidth label="OD Cilíndrico Máximo" value={item.odCilindricoMax}
                                     onChange={onChangeOdCilindricoMax} InputProps={{inputProps: {step: "any"}}}
                                     required={item.categoria.lente}/>
                </CustomRow>
                <CustomRow>
                    <CustomTextField type="number" fullWidth label="OE Cilíndrico Mínimo" value={item.oeCilindricoMin}
                                     onChange={onChangeOeCilindricoMin} InputProps={{inputProps: {step: "any"}}}
                                     required={item.categoria.lente}/>
                    <CustomTextField type="number" fullWidth label="OE Cilíndrico Máximo" value={item.oeCilindricoMax}
                                     onChange={onChangeOeCilindricoMax} InputProps={{inputProps: {step: "any"}}}
                                     required={item.categoria.lente}/>
                </CustomRow>
            </React.Fragment>
        )
    }

    const renderSelectMaterialColor1 = () => {
        return (
            <ForeignKey fullWidth required label="Cor" value={item.material1Color} fieldKey={'id'}
                        fieldDescription={'description'} labelDescription='Descrição' onChange={onChangeMaterial1Color}
                        onSelect={onSelectMaterial1Color} findAll={ColorService.findAll}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                        dataTypes={['number', 'text']}/>
        )
    }

    const renderSelectMaterialColor12 = () => {
        return (
            <React.Fragment>
                <ForeignKey fullWidth required label="Cor" value={item.material1Color} fieldKey={'id'}
                            fieldDescription={'description'} labelDescription='Descrição'
                            onChange={onChangeMaterial1Color}
                            onSelect={onSelectMaterial1Color} findAll={ColorService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                            dataTypes={['number', 'text']}/>
                <ForeignKey fullWidth required label="Cor" value={item.material2Color} fieldKey={'id'}
                            fieldDescription={'description'} labelDescription='Descrição'
                            onChange={onChangeMaterial2Color}
                            onSelect={onSelectMaterial2Color} findAll={ColorService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                            dataTypes={['number', 'text']}/>
            </React.Fragment>
        )
    }

    const renderSelectMaterialColor123 = () => {
        return (
            <React.Fragment>
                <ForeignKey fullWidth required label="Cor" value={item.material1Color} fieldKey={'id'}
                            fieldDescription={'description'} labelDescription='Descrição'
                            onChange={onChangeMaterial1Color}
                            onSelect={onSelectMaterial1Color} findAll={ColorService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                            dataTypes={['number', 'text']}/>
                <ForeignKey fullWidth required label="Cor" value={item.material2Color} fieldKey={'id'}
                            fieldDescription={'description'} labelDescription='Descrição'
                            onChange={onChangeMaterial2Color}
                            onSelect={onSelectMaterial2Color} findAll={ColorService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                            dataTypes={['number', 'text']}/>
                <ForeignKey fullWidth required label="Cor" value={item.material3Color} fieldKey={'id'}
                            fieldDescription={'description'} labelDescription='Descrição'
                            onChange={onChangeMaterial3Color}
                            onSelect={onSelectMaterial3Color} findAll={ColorService.findAll}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'description']}
                            dataTypes={['number', 'text']}/>
            </React.Fragment>
        )
    }

    const onChangeMaterial = (event) => {
        const newMaterial = event.target.value;
        setMaterial(newMaterial);
        setItem({
            ...item,
            material1Color: null,
            material2Color: null,
            material3Color: null
        });
    }

    function renderDadosPrincipais() {
        return tabValue === 0 && (
            <React.Fragment>
                <CustomTextField fullWidth autoFocus required label="Descrição" value={item.descricao}
                                 onChange={setDescricao}/>
                <ForeignKey fullWidth required label="Categoria" value={item.categoria} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCategoria}
                            onSelect={onAddCategoria} onAdd={onAddCategoria} findAll={findCategorias}
                            dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                            dataTypes={['number', 'text']} addComponent={<CategoryDetail/>}/>
                <CustomRow>
                    <FormControlLabel control={<Checkbox checked={item.ativo} onChange={setAtivo}/>}
                                      label="Ativo"/>
                    <FormControlLabel
                        control={<Checkbox checked={item.controlaEstoque} onChange={setControlaEstoque}/>}
                        label="Controla estoque"/>
                    <FormControlLabel label="Tem clipon"
                                      control={<Checkbox checked={item.clipon} onChange={setTemClipon}/>}/>
                </CustomRow>
                {renderEstoque()}
                <CustomRow>
                    <CustomTextField fullWidth label="Unidade" value={item.unidade || ""} onChange={setUnidade} inputProps={{maxLength: 6}}/>
                    <CustomTextField fullWidth label="NCM" value={item.ncm || ""} onChange={setNCM}/>
                    <CustomTextField fullWidth label="Prazo de entrega (dias)" value={item.prazoEntrega || ""}
                                     onChange={setPrazoEntrega}/>
                </CustomRow>
                <CustomTextField multiline fullWidth label="Observação" value={item.observacao || ''}
                                 onChange={setObservacao}/>
                <ForeignKey fullWidth label="Cor da lente" value={item.color} fieldKey={'id'}
                            required={item.categoria.lensColorRequired} fieldDescription={'description'}
                            labelDescription='Descrição' onChange={onChangeColor} onSelect={onSelectColor}
                            findAll={ColorService.findAll} dataColumnNames={['ID', 'Descrição']}
                            dataColumns={['id', 'description']} dataTypes={['number', 'text']}/>
                <CustomRow>
                    <CustomTextField type="number" fullWidth label="Horizontal" value={item.size || ''}
                                     onChange={onChangeSize} InputProps={{inputProps: {min: 0, step: "any"}}}
                                     required={item.categoria.sizeRequired}/>
                    <CustomTextField type="number" fullWidth label="Maior diagonal (mm)"
                                     value={item.biggestDiagonal || ''} onChange={onChangeBiggestDiagonal}
                                     required={item.categoria.frameSizeRequired}
                                     InputProps={{inputProps: {min: 0, step: "any"}}}/>
                    <CustomTextField type="number" fullWidth label="Vertical (mm)" value={item.vertical || ''}
                                     onChange={onChangeVertical} required={item.categoria.frameSizeRequired}
                                     InputProps={{inputProps: {min: 0, step: "any"}}}/>
                    <CustomTextField type="number" fullWidth label="Ponte (mm)" value={item.bridge || ''}
                                     onChange={onChangeBridge} required={item.categoria.frameSizeRequired}
                                     InputProps={{inputProps: {min: 0, step: "any"}}}/>
                    <CustomTextField type="number" fullWidth label="Curva base da lente"
                                     value={item.lensBaseCurve || ''} onChange={onChangeLensBaseCurve}
                                     InputProps={{inputProps: {min: 0, step: "any"}}}/>
                    <CustomTextField type="number" fullWidth label="Diâmetro" value={item.diameter || ''}
                                     onChange={onChangeDiameter} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                </CustomRow>
                <SelectOption fullWidth label="Material" value={material} required={item.categoria.materialRequired}
                              onChange={onChangeMaterial}
                              items={materialOptions} itemValue='name' itemDescription='description'/>
                {renderMaterialColors()}
                {renderLensDetails()}
            </React.Fragment>
        )
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <form onSubmit={save}>
            <Paper square sx={classes.productDetailTabBar}>
                <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleChangeTab}
                      variant={"scrollable"} scrollButtons={"auto"}>
                    <Tab label="Dados principais"/>
                    <Tab label="Valores"/>
                </Tabs>
            </Paper>
            {renderDadosPrincipais()}
            {renderValores()}
            {renderValorDialog()}
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit" disabled={!isMaster}>Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}
